<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>지원서 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value selected disabled>선택</option>
          <option value="username">이름</option>
          <option value="email">이메일</option>
          <option value="mobileNo">전화번호</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <!-- <div class="flex">
        <p class="bold">상태2</p>
        <label>
          <input type="radio" value checked v-model="status" />전체
        </label>
        <label>
          <input type="radio" value="ready" v-model="status" />
          진행전
        </label>
        <label>
          <input type="radio" value="on" v-model="status" />
          진행중
        </label>
        <label>
          <input type="radio" value="complete" v-model="status" />
          마감
        </label>
      </div>-->

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one planner">
      <h2 class="bold">
        <!-- {{ list[0].lectureId.title }}  -->
        {{ title }} | 지원자 목록 (총 {{ total }}명)
      </h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 15%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>이름</th>
          <th>이메일</th>
          <th>전화번호</th>
          <th>상태</th>
          <th>결과</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in list" :key="i">
          <td>
            <router-link to @click.native="handleMove(data._id)">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}
            </router-link>
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">
              {{ data.username }}
            </router-link>
          </td>

          <td>{{ data.email }}</td>
          <td>{{ data.mobileNo }}</td>
          <td>
            <el-tag
              :type="
                data.status == 'complete'
                  ? 'success' //초록
                  : 'incomplete'
                  ? 'info' //회색
                  : 'cancel'
                  ? 'danger' //빨강
                  : ''
              "
              size="small"
              >{{ data.status == "complete" ? "작성완료" : "작성중" }}</el-tag
            >
          </td>
          <td>{{ data.result }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="list.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchApplicationList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "applications",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      status: "",
      list: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
      title: "",
      lectureId: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    this.title = this.$route.query.title;
    this.lectureId = this.$route.query.id;
    this.getApplicationList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.status = "";
      this.currentPage = 1;
      this.list = [];
      this.total = 0;
      this.getApplicationList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getApplicationList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getApplicationList();
    },
    handleMove(id) {
      // this.$router.push({ name: "applicationDetail", query: { id: id } });
      // 예시: 페이지 이동 시 query 파라미터 유지
      this.$router.push({
        path: "/admin/application/detail",
        query: { id: id },
      });
    },
    getApplicationList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        status: this.status,
        lectureId: this.lectureId,
      };
      console.log("lectureId", this.lectureId);
      fetchApplicationList(params).then((res) => {
        if (res.data.status == 200) {
          this.list = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
